import React, { Component } from "react";
import logo from "./lumina.png"
import "./App.css";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from 'classnames';
import CardNer from "./CardNer"
import 'leaflet/dist/leaflet.css'
import { Progress } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { faTags } from '@fortawesome/free-solid-svg-icons'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { faChessKnight } from '@fortawesome/free-solid-svg-icons'
import { faPaintRoller } from '@fortawesome/free-solid-svg-icons'
import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import { faRuler } from '@fortawesome/free-solid-svg-icons'

const icoDB = <FontAwesomeIcon icon={faDatabase} />
const icoTable = <FontAwesomeIcon icon={faTable} />
const icoTags = <FontAwesomeIcon icon={faTags} />
const icoBulb = <FontAwesomeIcon icon={faLightbulb} />
const icoChess= <FontAwesomeIcon icon={faChessKnight} />
const icoPaint = <FontAwesomeIcon icon={faPaintRoller} />
const icoChart = <FontAwesomeIcon icon={faChartArea} />
const icoRuler = <FontAwesomeIcon icon={faRuler} />







const dataset1 = `Overall rank,Country or region,Score,GDP capita,Social support,Healthy life expectancy,Freedom to make life choices,Generosity,Perceptions of corruption
1,Finland,7.769,1.340,1.587,0.986,0.596,0.153,0.393
2,Denmark,7.600,1.383,1.573,0.996,0.592,0.252,0.410
3,Netherlands,7.488,1.396,1.522,0.999,0.557,0.322,0.298
4,Sweden,7.343,1.387,1.487,1.009,0.574,0.267,0.373
5,Austria,7.246,1.376,1.475,1.016,0.532,0.244,0.226
6,Luxembourg,7.090,1.609,1.479,1.012,0.526,0.194,0.316
7,Germany,6.985,1.373,1.454,0.987,0.495,0.261,0.265
8,Belgium,6.923,1.356,1.504,0.986,0.473,0.160,0.210
9,Czech Republic,6.852,1.269,1.487,0.920,0.457,0.046,0.036
10,Malta,6.726,1.300,1.520,0.999,0.564,0.375,0.151
11,France,6.592,1.324,1.472,1.045,0.436,0.111,0.183
12,Spain,6.354,1.286,1.484,1.062,0.362,0.153,0.079
13,Italy,6.223,1.294,1.488,1.039,0.231,0.158,0.030
14,Slovakia,6.198,1.246,1.504,0.881,0.334,0.121,0.014
15,Poland,6.182,1.206,1.438,0.884,0.483,0.117,0.050
16,Lithuania,6.149,1.238,1.515,0.818,0.291,0.043,0.042
17,Slovenia,6.118,1.258,1.523,0.953,0.564,0.144,0.057
18,Romania,6.070,1.162,1.232,0.825,0.462,0.083,0.005
19,Cyprus,6.046,1.263,1.223,1.042,0.406,0.190,0.041
20,Latvia,5.940,1.187,1.465,0.812,0.264,0.075,0.064
21,Estonia,5.893,1.237,1.528,0.874,0.495,0.103,0.161
22,Hungary,5.758,1.201,1.410,0.828,0.199,0.081,0.020
23,Portugal,5.693,1.221,1.431,0.999,0.508,0.047,0.025
24,Croatia,5.432,1.155,1.266,0.914,0.296,0.119,0.022
25,Greece,5.287,1.181,1.156,0.999,0.067,0.000,0.034
26,Bulgaria,5.011,1.092,1.513,0.815,0.311,0.081,0.004`


const dataset2 = `name,networth,country,company
Jeff Bezos,131,United States,Amazon
Bill Gates,96.5,United States,Microsoft
Larry Ellison,62.5,United States,Oracle
Mark Zuckerberg,62.3,United States,Facebook
Larry Page,50.8,United States,Alphabet
Lisa Su,1,Taiwan,AMD
Jack Dorsey,7.4,United States,Twitter
Marissa Mayer,1,United States,Yahoo!
Oprah Winfrey,30,United States,HARPO
Susan Wojcicki,1,United States,YouTube`

const dataset3 = `manufacturer,engine capacity,engine power (Kw),WLTP imperial medium,WLTP CO2,emissions CO [mg/km],noise level dB(A)
BENTLEY,5563.388888888889,412.94444444444446,19.33888888888889,329.88235294117646,285.6666666666667,70.33333333333333
DS Automobiles,1343.6206896551723,98.41379310344827,48.079310344827576,145.93103448275863,322.62068965517244,67.27586206896552
Fiat Automobiles,1163.9259259259259,63.876543209876544,48.09316770186336,144.52469135802468,573.3333333333334,70.33086419753086
FORD MOTOR COMPANY,1701.440203562341,112.38931297709924,44.96921119592873,169.8117048346056,243.35114503816794,68.52875318066158
JAGUAR,2497.346153846154,203.94230769230768,35.00336538461538,203.3653846153846,207.69230769230768,71.74615384615385
KIA MOTORS,1412.5579937304076,97.18495297805643,48.78264984227127,143.0188679245283,203.94670846394985,70.01724137931035
LAND ROVER,2565.7190476190476,205.5142857142857,29.58595238095236,231.01666666666668,240.76666666666668,69.2709523809524
LOTUS CARS,3219.1428571428573,288.0,27.97142857142857,229.71428571428572,658.2857142857143,73.85714285714286
MERCEDES-BENZ,2237.2763157894738,180.3048245614035,38.54035087719305,185.0065789473684,159.6688596491228,71.06140350877193
NISSAN,1683.157894736842,145.26315789473685,41.9842105263158,181.8684210526316,196.47368421052633,66.47368421052632
PEUGEOT,1496.9692307692308,100.61538461538461,48.910000000000004,150.65384615384616,280.4,68.6923076923077
Rolls-Royce Motor Cars,6675.733333333334,428.4,16.799999999999997,365.7,445.6,68.2
SSANGYONG MOTORS,1696.3548387096773,108.3225806451613,42.40645161290321,177.03225806451613,186.51612903225808,70.80000000000001
Volkswagen,1968.0,110.0,33.6,225.0,109.0,71.0`

// const dataset3 = `manufacturer,engine capacity,engine power (Kw),WLTP imperial medium,WLTP CO2,emissions CO [mg/km],noise level dB(A)
// ABARTH,1368.0,122.94444444444444,40.388888888888886,169.08333333333334,649.8333333333334,72.30555555555556
// ALFA ROMEO,1938.4642857142858,154.42857142857142,40.1875,181.625,289.60714285714283,67.75
// ASTON MARTIN,4573.473684210527,427.10526315789474,22.75789473684211,282.2631578947368,361.0,72.29473684210525
// BENTLEY MOTORS,5563.388888888889,412.94444444444446,19.33888888888889,329.88235294117646,285.6666666666667,70.33333333333333
// CHRYSLER JEEP,1664.8684210526317,116.13157894736842,41.17066666666668,184.55263157894737,279.35526315789474,69.86842105263158
// CITROEN,1423.6785714285713,88.17857142857143,48.963095238095235,151.1547619047619,272.3690476190476,68.52380952380952
// DS Automobiles,1343.6206896551723,98.41379310344827,48.079310344827576,145.93103448275863,322.62068965517244,67.27586206896552
// FIAT,1163.9259259259259,63.876543209876544,48.09316770186336,144.52469135802468,573.3333333333334,70.33086419753086
// FORD,1701.440203562341,112.38931297709924,44.96921119592873,169.8117048346056,243.35114503816794,68.52875318066158
// HONDA,1574.2597200622083,110.67496111975116,46.85318818040428,158.50388802488337,221.5692068429238,68.59564541213064
// HYUNDAI,1442.2407407407406,98.5,49.575,144.72222222222223,193.2314814814815,69.82685185185186
// JAGUAR,2497.346153846154,203.94230769230768,35.00336538461538,203.3653846153846,207.69230769230768,71.74615384615385
// KIA,1412.5579937304076,97.18495297805643,48.78264984227127,143.0188679245283,203.94670846394985,70.01724137931035
// LAND ROVER,2565.7190476190476,205.5142857142857,29.58595238095236,231.01666666666668,240.76666666666668,69.2709523809524
// LEXUS,2686.7820512820513,177.96794871794873,49.50833333333339,157.49358974358975,173.80128205128204,68.50320512820515
// LOTUS,3219.1428571428573,288.0,27.97142857142857,229.71428571428572,658.2857142857143,73.85714285714286
// MASERATI,3202.2258064516127,268.61290322580646,25.422580645161286,265.16129032258067,286.19354838709677,70.3774193548387
// MAZDA,1999.3259911894272,117.3920704845815,45.97444933920706,151.08370044052865,176.19383259911893,66.53832599118942
// MERCEDES-BENZ,2237.2763157894738,180.3048245614035,38.54035087719305,185.0065789473684,159.6688596491228,71.06140350877193
// MITSUBISHI,1541.2777777777778,104.38888888888889,39.43333333333334,175.05555555555554,410.6111111111111,67.77777777777777
// MORGAN MOTOR COMPANY,2860.0,162.5,29.25,222.5,325.5,74.0
// McLaren,3887.6363636363635,475.27272727272725,22.636363636363637,276.3636363636364,369.90909090909093,72.45454545454545
// NISSAN,1683.157894736842,145.26315789473685,41.9842105263158,181.8684210526316,196.47368421052633,66.47368421052632
// PEUGEOT,1496.9692307692308,100.61538461538461,48.910000000000004,150.65384615384616,280.4,68.6923076923077
// Rolls Royce,6675.733333333334,428.4,16.799999999999997,365.7,445.6,68.2
// SSANGYONG,1696.3548387096773,108.3225806451613,42.40645161290321,177.03225806451613,186.51612903225808,70.80000000000001
// SUBARU,1982.1538461538462,133.84615384615384,35.95384615384615,188.30769230769232,364.46153846153845,70.4
// SUZUKI,1189.0652173913043,79.77173913043478,45.665217391304346,141.5,406.9891304347826,68.39347826086954
// TOYOTA,1714.2906976744187,90.39240506329114,54.14302325581401,142.8139534883721,188.02325581395348,68.77093023255814
// VOLKSWAGEN C.V.,1968.0,110.0,33.6,225.0,109.0,71.0
// VOLVO,1918.929203539823,159.12389380530973,38.24015486725669,173.79424778761063,218.94247787610618,68.52212389380531`

const dataset4 = `character,gender,eye color,race,hair color,height,publisher,skin color,alignment,weight
Abin Sur,Male,blue,Ungaran,No Hair,185,DC Comics,red,good,90
Apocalypse,Male,red,Mutant,Black,213,Marvel Comics,grey,bad,135
Archangel,Male,blue,Mutant,Blond,183,Marvel Comics,blue,good,68
Ardina,Female,white,Alien,Orange,193,Marvel Comics,gold,good,98
Azazel,Male,yellow,Neyaphem,Black,183,Marvel Comics,red,bad,6706
Etrigan,Male,red,Demon,No Hair,193,DC Comics,yellow,neutral,203
Evilhawk,Male,red,Alien,Black,191,Marvel Comics,green,bad,106
Exodus,Male,blue,Mutant,Black,183,Marvel Comics,red,bad,88
Fin Fang Foom,Male,red,Kakarantharaian,No Hair,975,Marvel Comics,green,good,18
Gamora,Female,yellow,Zen-Whoberian,Black,183,Marvel Comics,green,good,77
Gladiator,Male,blue,Strontian,Blue,198,Marvel Comics,purple,neutral,268
Hulk,Male,green,Human  Radiation,Green,244,Marvel Comics,green,good,630
Joker,Male,green,Human,Green,196,DC Comics,white,bad,86
Killer Croc,Male,red,Metahuman,No Hair,244,DC Comics,green,bad,356
Kilowog,Male,red,Bolovaxian,No Hair,234,DC Comics,pink,good,324
Klaw,Male,red,Human,No Hair,188,Marvel Comics,red,bad,97
Lobo,Male,red,Czarnian,Black,229,DC Comics,blue-white,neutral,288
Toad,Male,black,Mutant,Brown,175,Marvel Comics,green,neutral,76
Triton,Male,green,Inhuman,No Hair,188,Marvel Comics,green,good,86
Ultron,Male,red,Android,-,206,Marvel Comics,silver,bad,331
Vision,Male,gold,Android,No Hair,191,Marvel Comics,red,good,135`

const dataset5= `planet,size
Mercury,4.880 	
Venus,12.104 	
Earth,12.756 	
Mars,6.794,3.397
Jupiter,142.984 
Saturn,108.728 	
Uranus,51.118 	
Neptune,49.532 	
Pluto,2.320`

const dataset6= `fruit,rank
apple,1
banana,2 	
orange,3`

const dataset7= `company,size
apple,1
banana,2 	
orange,3`

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      format: "TEXT",
      rows: 0,
      fields: 0,
      field_order: [],
      tabular: [],
      field_info: {},
      terms: {},
      categorical: [],
      sfields: "",
      viz_type: "unknown",
      extra_fields: [],
      enrich: false,
      metrics: {
        width: 0,
        height: 0,
        colors: 0,
        colorContrast: 0,
        dataInkRatio: 1,
        dataDensity: 1
      },
      activeTab: '1',
      progress: 0,
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleEnrich = this.handleEnrich.bind(this);
    this.handleSfields = this.handleSfields.bind(this);

    this.toggle = this.toggle.bind(this);


  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }


  getSVGURL(base64) {
    return "data:image/svg+xml;base64," + base64
  }

  // handleSfields = function(e){
  //   let lumi = window.Lumina;
  //   this.setState({sfields: e.target.value})
  //   let toks = e.target.value.split(" ")
  //   let drawn = false
  //   if (toks.length ==1) {

  //     let nFields = toks[0].split(",")
  //     let gFields = []
  //     console.log(nFields,gFields)
  //     lumi.data.grouped(nFields,gFields)
  //     let viz_type=lumi.viz.suggest_viz(nFields.concat(gFields))
  //     this.setState({viz_type: viz_type})
  //     lumi.viz.plot("#lumina",nFields.concat(gFields))
  //     drawn=true

  //   } else if (toks.length ==2){
  //     let nFields = toks[0].split(",")
  //     let gFields = toks[1].split(",")
  //     console.log(nFields,gFields)
  //     lumi.data.grouped(nFields,gFields)
  //     let viz_type=lumi.viz.suggest_viz(nFields.concat(gFields))
  //     this.setState({viz_type: viz_type})
  //     lumi.viz.plot("#lumina",nFields.concat(gFields))
  //     drawn=true

  //   }

  //   if (drawn){
  //     let cc =lumi.viz.metrics.checkColorContrast()
  //     let di =lumi.viz.metrics.calcDataDensity()
  //     let dd =lumi.viz.metrics.calcDataInkRatio()
  //     this.setState( {metrics: {
  //       width:lumi.viz.metrics.width,
  //       height:lumi.viz.metrics.height,
  //       colors:Array.from(lumi.viz.metrics.colors),
  //       colorContrast:cc,
  //       dataInkRatio:di,
  //       dataDensity:dd
  //     }})
  //     return
  //   }

  //   this.setState({viz_type: "unknown"})



  // }


  handleEnrich = function (e) {
    this.setState({ enrich: e.target.value })
  }

  handleSfields = function (e) {
    let lumi = window.Lumina;
    this.setState({ sfields: e.target.value })

    let phrase = e.target.value

    // extract color information

    let colF = ""
    let colS = ""

    let tp = ""

    if (phrase.endsWith(", vertical")) {
      tp = "vbar"
      phrase = phrase.replace(", vertical", "")
    } else if (phrase.endsWith(", radial")) {
      tp = "radial"
      phrase = phrase.replace(", radial", "")
    }

    console.log("does include", phrase.includes(", color "))

    if (phrase.includes(", color ")) {

      let phrases = phrase.split(", color ")
      phrase = phrases[0]
      console.log(phrase[1])
      let colTok = phrases[1].split(" ")
      if (colTok.length === 1) {
        if (colTok[0] === "random") {
          colS = "random"
        } else {
          colS = "field"
          colF = colTok[0]
        }
      } else if (colTok.length === 2 && (colTok[0] === "single" || colTok[0] === "flat")) {
        colS = "single"
        colF = colTok[1]
      } else if (colTok.length === 2 && (colTok[0] === "intensity" || colTok[0] === "var")) {
        colS = "intensity"
        colF = colTok[1]
      }
    }

    console.log("colorS", colF, colS)

    let toks = phrase.split(" ")




    let drawn = false
    if (toks.length === 1) {
      let nFields = toks[0].split(",")
      let gFields = []
      lumi.data.grouped(nFields, gFields)
      let viz_type = lumi.viz.suggest_viz(nFields.concat(gFields))
      this.setState({ viz_type: viz_type })
      lumi.viz.plot("#lumina", nFields.concat(gFields), colF, colS)
      drawn = true

    } else if (toks.length > 2) {

      let stage = 1
      let field = 0
      let field_sub = 0
      let nFieldAction = 1
      let nFieldNumber = 0
      let nFieldExpect = false
      let nFieldSort = false
      let nFields = []
      let gFields = []




      for (let tok of toks) {
        console.log(tok)
        if (nFieldExpect && !isNaN(tok)) {
          nFieldNumber = Number(tok)
          nFieldExpect = false

        }
        else if (tok === "top" || tok === "top" || tok === "biggest" || tok === "largest" || tok === "upper" || tok === "most") {
          nFieldAction = 1
          nFieldExpect = true
          nFieldSort = true

        } else if (tok === "bottom" || tok === "smallest" || tok === "lower" || tok === "least") {
          nFieldAction = -1
          nFieldExpect = true
          nFieldSort = true
        }
        else if (tok === "and" || tok === "vs" || tok === "versus") {
          field = field + 1
          field_sub = 0
        } else if (tok === "by" || tok === "per") {
          stage = 2
          field_sub = 0
          field = 0
        } else {
          if (nFieldExpect) {
            nFieldExpect = false;
            field_sub = 0;
          }
          if (field_sub === 0) {
            if (stage === 1) {
              nFields[field] = tok
            } else {
              gFields[field] = tok
            }
            field_sub = 1
          } else {
            if (stage === 1) {
              nFields[field] = nFields[field] + " " + tok
              console.log(tok, field, nFields[field])
            } else {
              gFields[field] = gFields[field] + " " + tok
              console.log(tok, field, gFields[field])
            }

          }
        }
      }

      console.log(nFields, gFields)

      // check the fields
      for (let i = 0; i < nFields.length; i++) {
        console.log(">", nFields[i])
        let checkField = lumi.data.checkField(nFields[i])
        if (checkField === "") {
          drawn = false
          this.setState({ viz_type: "unknown field: " + nFields[i] })
          return
        } else {
          nFields[i] = checkField
        }
      }

      // check the fields
      for (let i = 0; i < gFields.length; i++) {
        console.log(">", gFields[i])
        let checkField = lumi.data.checkField(gFields[i])
        if (checkField === "") {
          drawn = false
          this.setState({ viz_type: "unknown field: " + gFields[i] })
          return
        } else {
          gFields[i] = checkField
        }
      }

      if (nFieldSort) {
        lumi.data.grouped(nFields, gFields, "sum", nFields[0], nFieldNumber, nFieldAction)
      } else {
        lumi.data.grouped(nFields, gFields)
      }

      let viz_type = lumi.viz.suggest_viz(nFields.concat(gFields))
      this.setState({ viz_type: viz_type })
      lumi.viz.plot("#lumina", nFields.concat(gFields), colF, colS, tp)
      drawn = true

    }

    if (drawn) {
      // let cc =lumi.viz.metrics.checkColorContrast()
      // let di =lumi.viz.metrics.calcDataDensity()
      // let dd =lumi.viz.metrics.calcDataInkRatio()
      // this.setState( {metrics: {
      //   width:lumi.viz.metrics.width,
      //   height:lumi.viz.metrics.height,
      //   colors:Array.from(lumi.viz.metrics.colors),
      //   colorContrast:cc,
      //   dataInkRatio:di,
      //   dataDensity:dd
      // }})
      return
    }

    this.setState({ viz_type: "unknown" })



  }

  handleInput = function (e) {
    let lumi = window.Lumina;

    

    if (lumi) {
      lumi.sem.clearTerms();
      this.setState({progress:10});
      lumi.data.parse(document.querySelector("#master-input").value);
      this.setState({ format: lumi.data.format });
      this.setState({ rows: lumi.data.tabular.length });
      this.setState({ fields: lumi.data.field_order.length });
      this.setState({ field_info: lumi.data.fields });
      this.setState({ field_order: lumi.data.field_order });
      this.setState({ tabular: lumi.data.tabular });
      this.setState({progress:20});
    

      // lumi.sem.getTermsRemote(lumi.data.field_order).then(t=>{this.setState({ terms: lumi.sem.terms })});
      lumi.discover("lumina.dev:5000").then(t => {
        this.setState({progress:30});
        
        lumi.sem.getTermsRemote(lumi.data.fields).then(t => {
          this.setState({progress:60});
          lumi.sem.getSymbolsRemote(lumi.sem.hyp).then(t => {
            this.setState({progress:70});
            lumi.sem.getColorsRemote(lumi.sem.hyp).then(t => {
              this.setState({progress:90});

              this.setState({ terms: lumi.sem.terms, symbols: lumi.sem.symbols, colors: lumi.sem.colors, categorical: lumi.data.categorical })
            }).then(t => {
              this.setState({progress:100});
              if (this.state.enrich) {
                lumi.data.enrich(lumi.sem.terms);
                lumi.sem.getTermsRemote(lumi.data.field_order.concat(lumi.data.categorical)).then(t => {
                  lumi.sem.getSymbolsRemote(lumi.sem.hyp).then(t => {
                    lumi.sem.getColorsRemote(lumi.sem.hyp).then(t => {

                      this.setState({ terms: lumi.sem.terms, symbols: lumi.sem.symbols, colors: lumi.sem.colors, categorical: lumi.data.categorical })
                    }).then(t => {
                      this.setState({ format: lumi.data.format });
                      this.setState({ rows: lumi.data.tabular.length });
                      this.setState({ fields: lumi.data.field_order.length });
                      this.setState({ field_info: lumi.data.fields });
                      this.setState({ field_order: lumi.data.field_order });
                      this.setState({ tabular: lumi.data.tabular });
                      this.setState({ extra_fields: lumi.data.extra_fields })
                      
                    })
                  })
                })
              }
              this.setState({progress:0});
              this.setState({activeTab:'2'});

            })
          })
        })
      });
    }
  };

  render() {


    let tableHead = [];
    let tableRows = [];
    if (this.state.format !== "TEXT") {
      for (let item of this.state.field_order) {
        tableHead.push(<td key={item}>{item}</td>);
      }

      let indx = 0;

      for (let row of this.state.tabular) {
        let tableRow = [];
        for (let field in row) {
          tableRow.push(<td key={field}>{row[field]}</td>);
        }
        tableRows.push(<tr key={indx}>{tableRow}</tr>);
        indx++;
      }
    }

    let smallFieldList = []

    for (let field of this.state.field_order) {
      let f = this.state.field_info[field]

      if (this.state.extra_fields.indexOf(field) >= 0) {
        if (f !== undefined && f.field_type === "qualitative") {
          smallFieldList.push(<span className="small-field3">🏷 {f.name}</span>)
        } else {
          smallFieldList.push(<span className="small-field3"><em><strong>#</strong></em> {f.name}</span>)
        }
      } else {
        if (f !== undefined && f.field_type === "qualitative") {
          smallFieldList.push(<span className="small-field">🏷 {f.name}</span>)
        } else {
          smallFieldList.push(<span className="small-field2"><em><strong>#</strong></em> {f.name}</span>)
        }
      }


    }

    let fieldList = []
    // render fields
    for (let field of this.state.field_order) {
      let f = this.state.field_info[field]

      fieldList.push(<div key={field} className="card m-2 col-2">
        <div className="card-header"><h4>{field}</h4></div>
        <div className="card-body">
          {f.field_type} {"  "} {f.value_type}
          <hr />
          {f.categories && <div><span>categories:</span><br /><span>{JSON.stringify(f.categories)}</span></div>}
        </div>


      </div>)
    }

    let termList = []
    if (this.state.terms) {
      for (let term in this.state.terms) {
        let item = this.state.terms[term]
        let hyperList = []
        for (let h of item.hypernyms) {
          hyperList.push(<span className="m-2 p-2 border" key={h}>{h}</span>)
        }

        let extras = null
        if ("extras" in item) {
          extras = (<div>
            <span><CardNer data={item.extras} /></span>
          </div>)
        }

        termList.push(<div className="card pt-2 m-4" key={item.name}>
          <div className="card-header"><span className="m-2" style={{ fontWeight: "bold" }}>{item.name}</span><code>{item.semantic}</code></div>
          {extras}
          <div className="card-body"><div>{hyperList}</div></div>



        </div>)
      }



    }

    let symList = []
    if (this.state.symbols) {
      for (let term in this.state.symbols) {
        symList.push(<div key={term} className="card m-4 text-center"><img alt={"image for term " + term} className="img-small mx-auto" src={this.getSVGURL(this.state.symbols[term])} />
          <div className="card-body p-2 m-2">
            <span>{term}</span>
          </div>
        </div>)
      }
    }


    let colList = []
    if (this.state.colors) {
      for (let term in this.state.colors) {
        let color = this.state.colors[term]
        colList.push(<div key={term} className="card m-4 text-center"><div alt={"color " + color} className="swatch" style={{ backgroundColor: color }}></div>
          <div className="card-body p-2 m-2">
            <span>{term}</span>
            <div className="card-footer">
              {color}
            </div>
          </div>
        </div>)
      }
    }

    // let selected = [""]

    let prog = null;
    
    if (this.state.progress>0) {
      prog = ( 
      <div className="row mb-1"> 
        <div className="col-12">
           <Progress max="100" color="success" value={this.state.progress}></Progress>
        </div>
        </div>  
        )
    }

    console.log(this.state.progress)



    return (
      <div className="container-fluid">
        <div className="row">
          <div className="m-3">
            <div className="fixed-top p-3" style={{ display: "block", backgroundColor: "white" }}>
              {prog}
              <Nav pills>
                <img src={logo} alt="lumina" />
                <NavItem>
                  <NavLink href="#input"
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}>
                    {icoDB} Input
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#data"
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}>
                    {icoTable} Data Table
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#fields"
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}>
                    {icoTags} Fields
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#semantics"
                    className={classnames({ active: this.state.activeTab === '4' })}
                    onClick={() => { this.toggle('4'); }}>
                    {icoBulb} Semantics
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#assets"
                    className={classnames({ active: this.state.activeTab === '5' })}
                    onClick={() => { this.toggle('5'); }}>
                    {icoChess} Symbols
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#assets"
                    className={classnames({ active: this.state.activeTab === '6' })}
                    onClick={() => { this.toggle('6'); }}>
                    {icoPaint} Colors
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#viz"
                    className={classnames({ active: this.state.activeTab === '7' })}
                    onClick={() => { this.toggle('7'); }}>
                    {icoChart} Viz
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#viz"
                    className={classnames({ active: this.state.activeTab === '8' })}
                    onClick={() => { this.toggle('8'); }}>
                    {icoRuler} Metrics
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <br />

            <div className="mt-3 col-12">
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {/* {this.state.activeTab == 1 ? <h4>Tab 1 Contents</h4> : null} */}

                  <br />
                  <h3>Welcome to Lumina Workflow</h3>
                  <p>
                    This ui will enable you to follow the workflow steps of the
                    Lumina.js library
                  </p>

                  <div className="card">
                    <div className="card-header">
                      1. Insert Data{" "}
                    </div>



                    <div className="card-body">
                      <div>
                        Ready to use datasets:<br />
                        <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset1
                          el.focus()
                        }}>Europe Hapiness</button>
                        <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset2
                          el.focus()
                        }}>Use Forbes Billionaires 2019</button>
                        <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset3
                          el.focus()
                        }}>Cars</button>
                        <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset4
                          el.focus()
                        }}>Superheroes</button>
                        <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset5
                          el.focus()
                        }}>Planets</button>
                          <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset6
                          el.focus()
                        }}>Fruit mini example</button>
                          <button className="button btn-primary ml-2" onClick={() => {
                          let el = document.getElementById("master-input")
                          el.value = dataset7
                          el.focus()
                        }}>Company mini example</button>
                      </div>
                      <textarea
                        
                        className="form-control"
                        rows="4"
                        cols="80"
                        id="master-input"
                      />
                      <br />
                      <span>
                        <label >
                          <input
                            type="checkbox"
                            checked={this.state.enrich}
                            ref="complete"
                            onChange={this.handleEnrich}
                          />
          Enrich Input Results
        </label>
                        <br />
                        <button className="button btn-success" onClick={this.handleInput}>Import</button>
                        <br />

                      </span>
                    </div>

                  </div>




                </TabPane>
                <TabPane tabId="2">

                  {/* {this.state.activeTab == 1 ? <h4>Tab 1 Contents</h4> : null} */}

                  <br />

                  <div className="card mt-4">
                    <div className="card-header">
                      2. Analyze Data{" "}
                    
                    </div>

                    <div className="card-body">
                    <span> format: </span>{" "}
                      <span style={{ fontWeight: "bold" }}>{this.state.format}  (</span>
                      
                      <span style={{ fontWeight: "bold" }}>{this.state.fields}</span>
                      <span> fields *</span>{" "}
                      
                      <span style={{ fontWeight: "bold" }}>{this.state.rows}</span>
                      <span> rows )</span>{" "}
                      
               
                      
                      {/* <span>Json Tabular data:</span>
                      <br />
                      <code>{JSON.stringify(this.state.tabular)}</code> */}
                      <br />
                      
                      <table className="table table-striped">
                        <thead>
                          <tr>{tableHead}</tr>
                        </thead>
                        <tbody>{tableRows}</tbody>
                      </table>
                    </div>
                  </div>




                </TabPane>

                <TabPane tabId="3">

                  <br />

                  <div className="card mt-4">
                    <div className="card-header">
                      3. Fields{" "}
                      <a href="#analyze" name="insert">
                        ¶
                </a>
                    </div>

                    <div className="card-body">
                      <div className="row">
                        {fieldList}
                      </div>

                    </div>
                  </div>


                </TabPane>

                <TabPane tabId="4">

                  <br />

                  <div className="card mt-4">
                    <div className="card-header">
                      4. Semantics{" "}
                      <a href="#semantics" name="Semantics">
                        ¶
                </a>
                    </div>

                    <div className="card-body">
                      <h2>Semantic term list:</h2>
                      <div className="row">

                        {termList}
                      </div>





                    </div>
                  </div>


                </TabPane>

                <TabPane tabId="5">

                  <br />

                  <h2>Semantic term symbols:</h2>
                  <div className="row p-4">

                    {symList}
                  </div>


                </TabPane>



                <TabPane tabId="6">

                  <br />

                  <h2>Semantic term colors:</h2>
                  <div className="row p-4">
                    {colList}
                  </div>


                </TabPane>

                <TabPane tabId="7">

                  <br />

                  <div className="col-6">
                    <div className="card">
                      <div className="card-header text-center">
                        5. Select Fields
                      </div>
                      <div className="card-body">
                        <span>

                        </span>
                        <div>Available fields</div>
                        <div>{smallFieldList}</div>
                        <em>Use expressions in style of: "population and income by country" or "population per country" etc...</em>
                        <input id="sfields" className="form-control" type="text" defaultValue={this.state.sfields} onBlur={this.handleSfields}></input>
                        <strong>Visualization type recognized:</strong><code>{this.state.viz_type}</code>

                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="card">
                      <div className="card-header text-center">
                        6. Visualization
                      </div>
                      <div className="card-body">
                        <div id="lumina-container">
                          <svg id="lumina" width="1280" height="800"></svg>
                        </div>


                      </div>
                    </div>

                  </div>



                </TabPane>

                <TabPane tabId="8">

                <br />
            <div className="col-6">
              <div className="card">
                <div className="card-header text-center">
                  Visualization metrics <code>keyboard shortcut 'M'</code>
                </div>
                <div className="card-body">
                  <strong>Width:</strong> <code>{this.state.metrics.width}</code>
                  <strong className="ml-2">Height:</strong> <code>{this.state.metrics.height}</code>

                  <hr />
                  <strong>Visualization Type:</strong> <code>{this.state.viz_type}</code>
                  <hr />


                  <hr />
                  <strong>Data ink ratio:</strong> <code>{this.state.metrics.dataInkRatio}</code><br />
                  <hr />
                  <strong>Data desnity:</strong> <code>{this.state.metrics.dataDensity}</code><br />
                  <hr />
                  <strong>Colors used:</strong> <code>{this.state.metrics.colors.length}</code><br />
                  <strong>Colors similarity issue:</strong> <code>{this.state.metrics.colorContrast.toString()}</code>
                  <hr />


                </div>
              </div>
            </div>


                </TabPane>

              </TabContent>
            </div>
          </div>
        </div>
        <div className="row">


          <div className="col-12">












           
       






          </div>



        </div>
      </div>
    );
  }
}

export default App;
